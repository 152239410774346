* {
  font-family: "Urbanist", sans-serif;
}

body {
  background-color: white;
}
:root {
  --animate-delay: 0s;
}

.header img {
  height: 24px;
  margin: -10px;
  margin-bottom: -45px;
  padding: 15px;
  transition: 0.5s all;
}

.header img:hover {
  cursor: pointer;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);
}
.icons {
  position: fixed;
  top: 30px;
  right: 30px;
  padding: 10px;
  background: white;
  border-radius: 5px;
  z-index: 10;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);
}

.icons:hover {
  cursor: pointer;
  box-shadow: 0px 0px 25px -5px rgba(0, 0, 0, 0.25);
}

.icon-bar {
  font-size: 20px;
}

.icon-row {
  display: flex;
  align-items: center;
  transition: 0.3s all;
  padding: 10px;
}

.icon-row:hover {
  cursor: pointer;
  background: white;
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.5);
}
.icon-row h4 {
  margin: 0;
  padding: 0;
  font-size: 20px;
  margin-right: 10px;
  margin-top: 5px;
}
.icon-row p {
  margin: 0;
  padding: 0;
}
.keyboard-outline {
  margin: 0;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  line-height: 30px;
  border-radius: 4px;
  min-width: 10px;
  color: #333;
  border: 2px solid #333;
  background: #fafafa;
  margin-right: 10px;

  font-size: 16px;
}

.courses {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 90%;
  max-width: 1500px;
  margin: 0 auto;
  grid-gap: 15px;
  margin-bottom: 50px;
}
.course {
  padding: 25px;

  border: 1px solid #ccc;
  border-radius: 8px;
  position: relative;
  background-color: #fff;
  transition: 0.3s all;
}

.course:hover {
  cursor: pointer;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);
}

.content {
  display: flex;
  width: 95%;
  position: relative;
  overflow: auto;
  flex-direction: column;
  transition: 0.3s all;
}
.content::-webkit-scrollbar,
html::-webkit-scrollbar,
.right::-webkit-scrollbar,
.courses::-webkit-scrollbar {
  display: none;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.right {
  width: 35%;
  min-width: 400px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transition: 0.2s all;
  height: 100vh;
  padding: 25px;
  background: white;
  overflow: scroll;
  box-shadow: -10px 0px 117px -10px rgba(0, 0, 0, 0.25);
}

.course-top {
  display: flex;
  justify-content: space-between;
  padding-top: 3px;
}
.course-top h3 {
  margin: 0px;
  padding: 0px;
  color: #666;
  text-align: left;
}
.course-icons {
  display: grid;
  grid-template-columns: 1.65fr 1fr;
  margin-top: 15px;
}

.course-icons-expanded {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 15px;
}

.course h2 {
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
.course-icon {
  margin: 0;
  padding: 0;
  min-width: 25%;
  display: flex;
  margin-right: 20px;
  padding-bottom: 5px;

  color: #666;
  align-items: center;
}
.course-icon img {
  width: 20px;
}
.course-icon p {
  margin: 0;
  padding: 5px;
}
.course-icon a {
  text-decoration: none;
  padding: 5px;
  border-radius: 2px;
  color: #666;
  transition: 0.3s all;
  display: flex;
  align-items: center;
}
.course-icon a:hover {
  background: #f1f1f1;
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.5);
}
.loader-container {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.color-circle-green {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 5px;
  background: green;
}
.color-circle-yellow {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 5px;
  background: orange;
}
.color-circle-red {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 5px;
  background: red;
}
.large-text {
  font-size: 18px;
  font-weight: 600;
}
.small-text {
  font-size: 12px;
}
.searchFields {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin: 0 auto;
}

.instructor-rating {
  padding: 10px;
  margin: 0;
  margin-left: 5px;
  background: green;
  border-radius: 3px;
  color: white;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: fit-content;
  margin: 0 auto;
  padding-top: 50px;
}
.header h1 {
  font-size: 40px;
  font-weight: 800;
}
.header p {
  margin: 0;
  padding: 0;
}
.main-subtitle {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 5px;
}
.app-subtitle {
  font-size: 20px;
  padding: 0;
  margin: 0 auto;
  margin-top: -20px;
  margin-bottom: 40px;
  width: 90%;
  max-width: 800px;

  font-weight: lighter;
  text-align: center;
}

.course h5 {
  font-size: 16px;
  padding: 0;
  margin: 0;
  font-weight: lighter;
  width: 50%;
  text-align: right;
}
.course h6 {
  font-weight: lighter;
  margin: 0;
  margin-bottom: 10px;
  font-size: 14px;
}
.selected-courses {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 20px;
  margin-bottom: 50px;
}
.select-field {
  width: 350px;
  margin-right: 10px;
}

.wrapper-input {
  position: relative;
  width: 100%;
  max-width: 700px;
  margin-right: 40px;
}

.input-logo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -10px;
  font-size: 20px;
  color: #aaa;
}
.main-input {
  padding: 0;
  height: 60px;
  padding-left: 30px;
  margin: 5px;
  width: 100%;

  border: none;
  border-radius: 8px;
  background-color: white;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 25px -5px rgba(0, 0, 0, 0.25);
}
.select-field {
  font-weight: bolder;
}

.settings {
  padding-left: 20px;
  padding-right: 20px;
  height: 70px;
  align-items: center;
  display: flex;
  flex-direction: row;
  background: linear-gradient(180deg, #666, #333);
  font-size: 16px;
  color: white;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.settings p {
  margin-left: 10px;
}
.course-attributes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
}
.course-attribute {
  border-radius: 2px;
  margin: 5px;
  padding: 5px;
  background: #eafaff;
  font-size: 12px;
}

.course-alert {
  display: flex;
  background-color: #ffe5a3;
  align-items: center;
  padding: 10px;
  justify-content: start;
}

.course-button {
  position: absolute;
  bottom: 15px;
  right: 15px;
  background: linear-gradient(180deg, #888, #333);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  height: 48px;
  width: 48px;
  border-radius: 999px;
  border: 1px solid #000;
  opacity: 0;
  transition: 0.3s all;
}

.course-button-more {
  position: absolute;
  bottom: 70px;
  right: 15px;
  background-color: #aaa;
  color: white;
  font-size: 20px;
  line-height: 48px;
  height: 48px;
  border: none;
  width: 48px;
  border-radius: 999px;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
}

.course:hover .course-button,
.course:hover .course-button-more {
  opacity: 1;
  cursor: pointer;
}

.course-button:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.course-warning {
  display: flex;
  border-radius: 3px;
  background-color: #fec4c1;
  align-items: center;
  padding: 10px;
  justify-content: start;
}

.course-alert p,
.course-warning p {
  margin: 0;
  margin-left: 5px;
  padding: 0;
  margin-left: 10px;
  font-size: 14px;
  font-weight: lighter;
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19ab27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128d15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
  -moz-box-shadow: 0px 0px 3px 2px #0099e0;
  box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
  -moz-box-shadow: 0px 0px 5px 5px #0099e0;
  box-shadow: 0px 0px 5px 5px #0099e0;
}

.options {
  width: fit-content;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 100px;
  background-color: white;
  border-radius: 10px;
  padding: 70px 100px;

  box-shadow: 0px 0px 155px 5px rgba(0, 0, 0, 0.25);
}

.options-expanded-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.options-expanded-button-add {
  border: 1px solid #333;
  background: linear-gradient(180deg, #666, #333);

  color: white;
  padding: 10px 30px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: bolder;
  cursor: pointer;
  transition: 0.3s all;
}
.options-expanded-button-close {
  border: 1px solid #333;
  background: white;
  color: #333;
  padding: 10px 30px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: bolder;
  cursor: pointer;
  transition: 0.3s all;
}

.options-preq-checker-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-top: 40px;
}
.options-preq-checker-status span {
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 2px 20px;
  line-height: 0px;
  height: min-content;
  color: white;
}

.red-bg {
  background: darkred;
}
.green-bg {
  background: green;
}

.options-preq-checker-status span p {
  color: white;
  margin-right: 10px;
  font-size: 20px;
  font-weight: lighter;
}

.react-loader {
  padding: 100px;
  width: fit-content;
  margin-top: 450px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}
.app-settings-subtitle {
  margin-top: -20px;
  font-size: 18px;
}

.options h5 {
  font-size: 16px;
  margin: 0;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 600;
}
.options-content {
  display: flex;
  justify-content: space-around;
  align-items: start;
}
.option-button {
  display: none;
}

.options-expanded-header {
  font-size: 20px;
  margin-bottom: 0px;
}

.options-preq-selectors {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 10px;
  margin-bottom: -30px;
}
.options-preq-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.options-expanded-status {
  font-size: 20px;
}

.custom-checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 22px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 3px;
}

.custom-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.custom-checkbox-container:hover input ~ .checkmark {
  background-color: #eee;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox-container input:checked ~ .checkmark {
  background-color: #333;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.options-content-left {
  width: fit-content;
  margin-right: 100px;
}

.options-content-middle {
  margin-left: 20px;
  margin-right: 20px;
  width: 40%;
}

.options-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: fit-content;
}
.options h2 {
  margin-bottom: 0px;
  margin-right: 25px;
  margin-top: 0px;
  font-size: 30px;
}
.options-header p {
  margin-right: 10px;
}
.options h3 {
}
.option {
  display: flex;
  align-items: center;
}
.option p {
  margin-right: 10px;
}
.extra-padding {
  padding-top: 5px;
  padding-bottom: 5px;
}
h1 {
  font-size: 40px;
  padding: 10px;
}

.toggle_modal {
  border: 1px solid #333;
  background: white;
  padding: 10px 30px;
  margin: 0 auto;
  margin-top: 20px;
  display: block;
  transition: 0.3s all;
}

.toggle_modal:hover {
  background: #f1f1f1;
  color: white;
  cursor: pointer;
}

.introscreen-bg {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  height: 100vh;
  background: rgba(50, 50, 50, 0.2);
  backdrop-filter: blur(5px);
  overflow: scroll;
}

.introscreen {
  background: white;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 50px;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  border-radius: 15px;
  padding: 50px;
}
.introscreen-features {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 95%;
  max-width: 900px;
  grid-gap: 10px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.coming-soon {
  border: 3px solid gold;
}

.introscreen h3 {
  font-size: 32px;
}

.introscreen-feature {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);
}

.introscreen-feature img {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}
.introscreen-feature-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.introscreen button {
  border: 0;
  background: linear-gradient(180deg, #666, #333);
  color: white;
  height: 50px;
  width: 90%;
  max-width: 300px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: bolder;
  cursor: pointer;
  transition: 0.3s all;
}

.introscreen button:hover {
  filter: opacity(0.8);
}

.introscreen-feature-text h4 {
  margin: 0;
  padding: 0;
}
.introscreen-feature p {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  font-size: 14px;
  font-weight: lighter;
}
.introscreen h2 {
  font-size: 32px;
  margin: 0;
  margin-bottom: 15px;
}
.introscreen h5 {
  margin: 0;
  font-weight: lighter;
  font-size: 18px;
}

.introscreen h6 {
  font-size: 20px;
  text-align: center;
}

.calendar-slot {
  height: 2px;
  width: 100%;
  border-radius: 1px;
  position: relative;
}

.calendar-tooltip {
  position: absolute;
  top: 0;
  background: white;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  transition: 0.3s all;
  padding: 15px;
  left: 0;
  z-index: 1;
  opacity: 0;
  width: 100px;
}

.calendar-tooltip h5,
.calendar-tooltip h6 {
  margin: 0px;
  margin-bottom: 5px;
}

.calendar-slot:hover .calendar-tooltip {
  cursor: pointer;
  transition: 0.1s all;
  opacity: 1;
}

.border-top {
  height: 0px;
  border-top: 1px solid #aaa;
}

.calendar-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;

  padding: 10px;
  border-radius: 3px;
  transition: 0.1s all;
}

.no-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 20px;
  font-weight: lighter;
  color: #666;
}
.no-results p {
  margin: 0;
  margin-top: -15px;
  margin-bottom: 10px;
}

.no-results-content {
  display: flex;
  align-items: center;
  color: #333;
}
.no-results-content h6 {
  font-size: 20px;
}

.calendar-close:hover {
  cursor: pointer;
  background: #f1f1f1;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);
}

.calendar {
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);
  padding-top: 25px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 25px;
}

.calendar-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.calendar-header button {
  display: flex;
  border: 1px solid #666;

  border-radius: 3px;
  align-items: center;
  background: white;
  color: #666;
  cursor: pointer;
  padding: 0px 15px;
}

.calendar-header button:hover {
  background: #f1f1f1;
  transition: 0.3s all;
}
.calendar-header button svg {
  font-size: 18px;
  margin-left: 10px;
}
.calendar-header button p {
  line-height: 10px;
}

.right-subtitle {
  margin: 0;
  margin-top: -15px;
  margin-bottom: 20px;
  padding: 0;
}
.course-color {
  height: 10px;
  width: 10%;
  margin-bottom: 20px;
}
.calendar-day {
  margin: 2px;
  width: calc((100% / 5));
}
.slot-label-column {
  width: 10px;
  margin-right: -2px;
  padding-right: 2px;
}

.slot-label {
  text-align: right;
  width: 10px;

  font-size: 10px;
}

.slot-empty {
  background-color: white;
  transition: 0.3s all;
}
.slot-filled {
  background-color: #49a7c5;
  transition: 0.3s all;
}

.calendar-list {
  display: flex;
  margin-top: -10px;
  margin-bottom: 10px;
}
.calendar-item {
  margin: 2px;
  padding: 5px 15px;
  font-size: 22px;
  font-weight: lighter;
  cursor: pointer;
  transition: 0.3s all;
  border-radius: 3px;

  border: 1px solid #ccc;
}
.active-calendar {
  background: linear-gradient(180deg, #666, #333);
  color: white;
  border: 1px solid #333;
  transition: 0.3s all;
}
.empty-calendar {
  border: 1px solid #ccc;
  padding: 100px 10px;
  margin-top: -50px;
  text-align: center;
  color: #888;
  transition: 0.3s all;
  border-radius: 10px;
}

.calendar-item h6 {
  margin: 0;
  padding: 0;
  margin-left: 5px;
  text-align: center;
  margin-right: 5px;
}
.calendar-item:hover {
  background: #f1f1f1;
  transition: 0.3s all;
}
.active-calendar:hover {
  background: linear-gradient(180deg, #888, #333);
  color: white;
  border: 1px solid #333;
  transition: 0.3s all;
}

.calendar-item p {
  margin: 0;
  padding: 0;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 14px;
  font-weight: lighter;
  text-align: center;
}

.calendar-day p {
  margin: 0;
  margin-bottom: 5px;
  font-size: 12px;
  text-align: center;
  background: #f1f1f1;
  padding-top: 5px;
  padding-bottom: 5px;
}
.time-select {
  height: 50px;
  width: 100%;
}

.time-options {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 10px;
  margin-top: 15px;
}
.time-options h5 {
  margin: 0;
}
.courses-found {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.mobile-calendar {
  display: none;
}
@media screen and (max-width: 1500px) {
  .courses {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 1000px) {
  .icons {
    display: none;
  }

  .right {
    position: fixed;
    width: 90%;
    border-radius: 20px;
    height: 100vh;
  }
  .mobile-calendar {
    position: fixed;
    z-index: 15;
    bottom: 30px;
    right: 15px;
    display: flex;
    align-items: center;
    background: linear-gradient(180deg, #666, #333);
    padding: 0px 15px;
    border-radius: 999px;
    color: white;
    font-size: 18px;
  }
  .mobile-calendar p {
    margin-left: 10px;
    font-weight: bold;
    font-size: 16px;
  }
  .introscreen {
    padding: 30px;
  }
  .introscreen-bg {
    height: max-content;
    overflow: auto;
    min-height: 100vh;
  }
}

@media screen and (max-width: 850px) {
  .introscreen-feature-text p {
    display: none;
  }
  .introscreen-feature img {
    width: 40px;
    height: 40px;
  }
  .introscreen h6 {
    font-size: 16px;
  }
}

@media screen and (max-width: 700px) {
  .options-preq-selectors {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .course-icons,
  .course-icons-expanded {
    grid-template-columns: 1fr;
  }
  .options-expanded-buttons {
    padding-bottom: 150px;
  }
  .course-top {
    flex-direction: column-reverse;
  }
  .course-top h5 {
    text-align: left;
  }
  .course-top h3 {
    font-size: 16px;
  }
  .course h2 {
    font-size: 20px;
  }
  .searchFields {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
    margin: 0 auto;
  }
  .wrapper-input {
    width: 90%;
  }
  .main-input {
    padding-left: 30px;
  }
  input[type="text"],
  .select-field,
  .settings {
    margin-top: 10px;
  }
  .no-results-content {
    display: none;
  }
  .settings {
    position: fixed;
    bottom: 30px;
    left: 15px;
    width: fit-content;
    height: fit-content;
    padding: 2px 15px;
    border-radius: 999px;
    z-index: 15;
  }
  .calendar-list {
    flex-direction: column;
  }
  .introscreen-feature {
    padding: 10px;
  }
  .keyboard-outline {
    display: none;
  }
  .options-header p {
    display: none;
  }
  .options-content {
    width: 100%;
  }
  .options-content-right {
    display: none;
  }
  .options-content-left {
    width: 100%;
    margin-right: 0;
  }
  .options {
    height: calc(100vh - 100px);
    padding: 50px 50px;
    margin-top: 0px;

    padding-bottom: 250px;
    overflow: scroll;
  }
  .option-button {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    width: 100%;
    color: #333;
    background: white;
    padding-top: 15px;
    padding-bottom: 11px;
    font-weight: bold;
    font-size: 18px;
    border: 2px solid #333;
    border-radius: 3px;
    margin-bottom: 100px;
  }

  .course-button,
  .course-button-more {
    opacity: 1;
  }

  .empty-calendar {
    margin-bottom: 150px;
  }
  .selected-courses {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 500px) {
  .courses {
    grid-template-columns: 1fr;
  }
  .main-input {
    font-size: 14px;
  }
  .introscreen-feature img {
    width: 24px;
    height: 24px;
  }
  h1 {
    font-size: 28px;
  }
  .app-subtitle {
    font-size: 18px;
  }
}
